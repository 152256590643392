





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class InsCalendar extends Vue {
    currentDate: Date = new Date();

    eventList: any[] = [];

    getCalendarEvents () {
      let year = this.currentDate.getFullYear();
      let month = this.currentDate.getMonth() + 1;
      this.$Api.calendar.GetListByCond({ Month: month, Year: year }).then(result => {
        if (result.Succeeded) {
          this.eventList = result.ReturnValue.Data;
        }
      });
    }

    // 处理显示当日活动数量
    dealEventDate (v) {
    //   let dateStart = new Date(v + 'T00:00:00');
    //   let dateEnd = new Date(v + 'T24:00:00');
      let num = 0;
      for (let i = 0; i < this.eventList.length; i++) {
        // if (new Date(this.eventList[i].start) < new Date(v) && new Date(v) < new Date(this.eventList[i].end)) {
        //   num++;
        // } else if ((new Date(this.eventList[i].start) >= dateStart && new Date(this.eventList[i].start) < dateEnd) || (new Date(this.eventList[i].end) >= dateStart && new Date(this.eventList[i].end) < dateEnd)) {
        //   num++;
        // }

        if (new Date(this.eventList[i].start.split(' ')[0]) <= new Date(v) && new Date(v) <= new Date(this.eventList[i].end.split(' ')[0])) {
          num++;
        }
      }

      return num;
    }

    onDate (date, type) {
      console.log(this.currentDate, 'currentDate');
      if (type === 'current-month') {
        this.$router.push({ name: 'eventList', params: { date } });
      }
    }

    created () {
      this.getCalendarEvents();
    }

    mounted () {
      (document.querySelector('.el-calendar__header .el-button--plain:first-child span') as any).innerHTML = '❮';
      (document.querySelector('.el-calendar__header .el-button--plain:last-child span') as any).innerHTML = '❯';
    }

    @Watch('currentDate', { deep: true })
    onDayChange () {
      this.getCalendarEvents();
      console.log(this.currentDate, 'currentDate');
    }
}
